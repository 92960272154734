import { instance } from "./api_instance";

//获取用户信息
export const getUserProfile = async (addressOrEns, network) => {
  return (await instance.get("/v1/profiles/" + addressOrEns, { params: { network } })).data;
};
export const getUserProfileByList = async (addressOrEnsList) => {
  return (await instance.post("/v1/profiles", JSON.stringify({ address: addressOrEnsList }))).data;
};
//获取用户数据流
export const getUserMessage = async (addressOrEns, limit, tag) => {
  if (tag) return (await instance.get("/v1/notes/" + addressOrEns, { params: { limit, tag } })).data;
  else return (await instance.get("/v1/notes/" + addressOrEns, { params: { limit } })).data;
};
//获取g更多的用户数据流
export const getUserMessageWithCursor = async (addressOrEns, limit, tag, cursor) => {
  if (tag) return (await instance.get("/v1/notes/" + addressOrEns, { params: { limit, tag, cursor } })).data;
  else return (await instance.get("/v1/notes/" + addressOrEns, { params: { limit, cursor } })).data;
};
//获取用户数据流
export const getUserMessageByList = async (addressOrEnsListStr, limit, tag) => {
  if (tag)
    return (
      await instance.post(
        "/v1/notes",
        JSON.stringify({ address: (addressOrEnsListStr || []).slice(0, 10), limit, tag })
      )
    ).data;
  else
    return (
      await instance.post("/v1/notes", JSON.stringify({ address: (addressOrEnsListStr || []).slice(0, 10), limit }))
    ).data;
};
//获取g更多的用户数据流
export const getUserMessageWithCursorByList = async (addressOrEnsListStr, limit, tag, cursor) => {
  if (tag)
    return (
      await instance.post(
        "/v1/notes",
        JSON.stringify({ address: (addressOrEnsListStr || []).slice(0, 10), limit, tag, cursor })
      )
    ).data;
  else
    return (
      await instance.post(
        "/v1/notes",
        JSON.stringify({ address: (addressOrEnsListStr || []).slice(0, 10), limit, cursor })
      )
    ).data;
};
