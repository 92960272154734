import React from 'react';
import ReactDOM from 'react-dom/client';
import './less/style.less'
import AppRouter from "./router";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    // <React.StrictMode>
        <AppRouter />
    // </React.StrictMode>
);

let heightTemp = '100vh';
const fitWindow = () => {
    heightTemp = (window.innerHeight) + "px";
    document.getElementById("root").style.height = heightTemp;
    document.getElementById("root").style.overflow = "hidden";
    window.addEventListener("resize", () => {
        if (window.innerHeight - Number(heightTemp.replace("px", "")) < -50) {
        }
        else {
            heightTemp = (window.innerHeight) + "px";
        }
        document.getElementById("root").style.height = heightTemp;
    });
    window.addEventListener("DOMContentLoaded", () => {
        if (window.innerHeight - Number(heightTemp.replace("px", "")) < -50) {
        }
        else {
            heightTemp = (window.innerHeight) + "px";
        }
        document.getElementById("root").style.height = heightTemp;
    });

    let lastTouchEnd = 0
    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    })
    document.addEventListener('touchend', function (event) {
        let now = (new Date()).getTime()
        if (now - lastTouchEnd <= 300) {
            event.preventDefault()
        }
        lastTouchEnd = now
    }, false)
    // 阻止双指放大
    document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
    })
}

setTimeout(() => {
    fitWindow()
}, 1000)

