import axios from "axios";

// export const baseUrl = "http://47.243.39.240:8000";
export const baseUrl = ''//process.env.FEED_URI;
// export const baseUrl = "http://192.168.1.109:8000";
// export const baseUrl = "http://10.1.5.76:8000";
const instance = axios.create({
    baseURL:`${baseUrl}/api`,
    timeout:10*1000,
    retry:3,
    retryDelay:1000,
})
//拦截器 -- 请求拦截
instance.interceptors.request.use(config=>{
    //添加头实例token
    //sessionStorage 内容动态设置baseurl
    //config.url = sessionStorage["内容动态设置baseurl"] + config.url

    // config.headers["content-type"]= 'application/json';
    // config.headers["accept"]="application/json";
    // console.log(config.url)
    // console.log(config)
    return config;
},error => {
    console.log("request err")
    return  Promise.reject(error)
})
//拦截器 -- 响应拦截
instance.interceptors.response.use(res=>{
    // console.log(res)
    return res;
},err=> {
    console.log("axiosRetryInterceptor-----",err)
    //出错重发4次间隔一秒
    let config = err.config;
    // If config does not exist or the retry option is not set, reject
    if(!config || !config.retry) return Promise.reject(err);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if(config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(err);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    let backoff = new Promise(function(resolve) {
        setTimeout(function() {
            resolve();
        }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
        if(err.response.status===403 || err.response.status===500) {
            return Promise.reject(err);
        }
        return instance(config);
    });
})
export {instance};
