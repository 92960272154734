import React, {useContext, useEffect, useState} from "react";
import style from './style.module.less'
import {DotLoading, InfiniteScroll, Popup, PullToRefresh, Toast} from "antd-mobile";
import {sleep} from "antd-mobile/es/utils/sleep";
import HeaderView from "../../components/HeaderView";
import {get_AuthCode, getUserFriends, getUserInfo, getAvatar} from "../../api/api_Feeds_Auth/api";
import ListView from "../../components/ListView";
import SelectView from "../../components/SelectView";
import {
    getUserMessageByList,
    getUserMessageWithCursorByList,
    getUserProfile,
    getUserProfileByList
} from "../../api/api_Feeds/api";
import Helper from "../../utils/helper";
import note_edit from "../../asset/image/v1/blue/note_edit.svg";
import note_post from "../../asset/image/v1/blue/note_post.svg";
import profile_create from "../../asset/image/v1/blue/profile_create.svg";
import profile_link from "../../asset/image/v1/blue/profile_link.svg";
import NFT_trade_out from "../../asset/image/v1/pink/NFT_trade_out.svg";
import NFT_mint from "../../asset/image/v1/pink/NFT_mint.svg";
import NFT_trade_in from "../../asset/image/v1/pink/NFT_trade_in.svg";
import donation_launch from "../../asset/image/v1/green/donation_launch.svg";
import Token_withdraw from "../../asset/image/v1/orange/Token_withdraw.svg";
import unknown_in from "../../asset/image/v1/black/unknown_in.svg";
import unknown_burn from "../../asset/image/v1/black/unknown_burn.svg";
import Token_deposit from "../../asset/image/v1/orange/Token_deposit.svg";
import Token_liquidity from "../../asset/image/v1/orange/Token_liquidity.svg";
import Token_swap from "../../asset/image/v1/orange/Token_swap.svg";
import governance_vote from "../../asset/image/v1/yellow/governance_vote.svg";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getUserSubscribeList, subscribe} from "../../api/api_FeedsServer/api";
import AvatarSubscribeView from "../../components/AvatarSubscribeView";
import { getPlatformLogo, getNetworkLogo } from "../../utils";
import UserContext from "../../components/theme";
import failSvg from '../../asset/image/failSvg.svg'

let Home_isLoading = false;
let Start = false;
const pageSize = 20;
const AddressMap = {};
let userCardsRemainder = pageSize;
let myFriendsList = [];
const MyHome = () => {
    const {address} = useContext(UserContext)
    const [params] = useSearchParams();
    let navigate = useNavigate()
    const [addressCur, setAddressCur] = useState(address);//'kallydev.eth'
    const [userCards, setUserCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [requestTag, setRequestTag] = useState("")
    const [showFilter, setShowFilter] = useState(false)
    const [selectRange, setSelectRange] = useState("All")
    const [myFriends,setMyFriends] = useState([])
    const [myInfo,setMyInfo] = useState({});
    const [searchAddress,setSearchAddress] = useState("")
    const [searchInfo,setSearchInfo] = useState({})
    const [isSubscribed,setIsSubscribed] = useState(false);
    const [showConnect,setShowConnect] = useState(true)
    const [avatar, setAvatar] = useState('')
    // const [myContacts,setMyContacts] = useState([])
    useEffect(() => {
        update();
        return () => {
            Home_isLoading = false;
        }
    }, [])
    useEffect(()=>{
        console.log(`addressCur`,addressCur )
        // setIsSubscribed(isSubscribe(searchAddress));
        getNextData().then(()=>{
            if(searchAddress){
                getUserProfile(searchAddress).then((res)=>{
                    if(res.total > 0){
                        setIsSubscribed(isSubscribe(res.result[0].address));
                    }
                });
            }

        })
    },[addressCur,searchAddress,myFriends])
    useEffect(()=>{
        const work = async () => {
            let getUserFriendsRes = await getUserSubscribeList(localStorage["address"]);
            //console.log(`getUserFriendsRes`,getUserFriendsRes)
            if(getUserFriendsRes.ret==="ok"){
                localStorage["mycontacts"] = JSON.stringify(getUserFriendsRes.result)
                // canUpdateFriendsList(getUserFriendsRes.result) && setMyFriends(myFriends.concat(getUserFriendsRes.result))
            }
        }
        work();
    },[])
    useEffect(()=>{
        const work = async () => {
            console.log(`selectRange`,selectRange)
            if(selectRange === "All"){
                let res = await getUserFriends()
                let myFriendsTemp = [];
                for(let i = 0 ; i < res.contacts.length;i++){
                    myFriendsTemp.push(res.contacts[i].wallet_address);
                }
                let myContacts = JSON.parse(localStorage["mycontacts"]);
                console.log(`myContacts`,myFriendsTemp,myContacts)
                if(myContacts.length > 0){
                    myFriendsTemp = myFriendsTemp.concat(myContacts)
                    console.log(`myContacts`,myFriendsTemp,myContacts)
                }
                else{
                    let getUserFriendsRes = await getUserSubscribeList(localStorage["address"]);
                    //console.log(`getUserFriendsRes`,getUserFriendsRes)
                    if(getUserFriendsRes.ret==="ok"){
                        localStorage["mycontacts"] = JSON.stringify(getUserFriendsRes.result)
                        myFriendsTemp = myFriendsTemp.concat(getUserFriendsRes.result)
                        //console.log(`myContacts`,myFriendsTemp,myContacts)
                    }
                }
                setMyFriends(myFriendsTemp)
            }
            else{
                let res = await getUserFriends()
                let myFriendsTemp = [];
                for(let i = 0 ; i < res.contacts.length;i++){
                    myFriendsTemp.push(res.contacts[i].wallet_address);
                }
                setMyFriends(myFriendsTemp)
            }
        }
        work();
    },[selectRange,addressCur])

    const update = () => {
        //console.log("update",Home_isLoading)
        if (Home_isLoading) {
            return;
        }
        Home_isLoading = true;
        init();
        //console.log(window.location.href)
    }

    async function init() {
        if(!addressCur) return;
        try {
            let res = await getUserFriends()
            let myFriendsTemp = [];
            for(let i = 0 ; i < res.contacts.length;i++){
                myFriendsTemp.push(res.contacts[i].wallet_address);
            }
            // canUpdateFriendsList(myFriendsTemp) && setMyFriends(myFriendsTemp)
        }catch (e){

        }
        const myProfiles = await getUserInfo();
        const avatar = getAvatar('', myProfiles.avatar_url);
        let info = {
            avatar: avatar || failSvg,
            avatarTitle: myProfiles['displayname'],
            address: myProfiles['wallet_address'] || addressCur
        }
        setMyInfo(info)
        // loadMore()
        getNextData()
    }

    async function GetProfiles(address) {
        try {
            const result = await getUserProfile(address);
            // //console.log(result)
            if (result.err || result.total === 0) {
                return ;
            }

            let bio = "";

            for (let ii = 0; ii < result.result.length; ii++) {
                if (result.result[ii].bio !== "") {
                    bio = result.result[ii].bio
                }
            }

            for (let ii = 0; ii < result.result.length; ii++) {
                if (result.result[ii].network === "ethereum") {
                    AddressMap[result.result[ii].address] = result.result[ii].name;
                    result.result[ii].bio = bio;
                    return new Promise(function (resolve, reject) {
                        resolve(result.result[ii]);
                    });
                }
            }
        } catch (err) {
            //console.log(err.message)
        } finally {

        }

    }

    const AddressToENS = (address) => {
        return AddressMap[address] || address.slice(0, 6) + "..." + address.slice(address.length - 4, address.length);
    }

    async function GetNotes(address, bCursor) {
        // //console.log(`myFriends`,myFriends)
        if(myFriends.length===0 && !searchAddress ) {
            userCardsRemainder = false;
            setHasMore(false);
            // setUserCards([])
            setLoading(false);
            return ;
        }
        try {
            let cursor = "";
            let result;
            if (myFriends.length >= 15) {
                Toast.show({
                    content: 'too many subsciptions, it may cause error',
                    maskClickable: false,
                }) 
            }
            if (userCards.length > 0 && bCursor) {
                cursor = userCards[userCards.length - 1].hash;
                // result = await getUserMessageWithCursor(address, pageSize, requestTag, cursor)
                result = await getUserMessageWithCursorByList(searchAddress?[searchAddress]:myFriends, pageSize, requestTag && [requestTag], cursor)
            } else {
                // result = await getUserMessage(address, pageSize, requestTag)
                result = await getUserMessageByList(searchAddress?[searchAddress]:myFriends,pageSize, requestTag && [requestTag])
            }

            //console.log('result total: ', result.total);
            userCardsRemainder = result.total;

            console.log('999res', result)

            var list = [];
            for (var ii = 0; ii < result.result.length; ii++) {
                var value = result.result[ii];
                //console.log(ii)
                var item = {};
                item.hash = value.hash;
                item.address=value.owner;
                item.owner = AddressToENS(value.owner);

                item.timestamp = Helper.formatTime(value.timestamp);
                item.actions1 = value.actions[0].type;
                item.actions2 = 'on';
                item.actions3 = value.actions[0].platform;
                item.title = value.actions[0].metadata.title;
                if (value.actions[0].type === "mint") {
                    item.image = {};
                    item.image.name = Helper.FixNumber(value.actions[0].metadata.value_display) + ' ' + value.actions[0].metadata.symbol;
                    item.image.platform = value.actions[0].metadata.symbol;
                    item.image.description = value.actions[0].metadata.description;
                    item.image.image = value.actions[0].metadata.image;
                    item.image.size = '24px'
                    item.actions2 = 'on ';
                    item.actions3 = value.actions[0].metadata.symbol;
                } else if (value.type === "profile") {
                    if (value.actions[0].metadata.name != null && value.actions[0].metadata.bio !== "") {
                        item.image = {};
                        item.image.name = value.actions[0].metadata.name;
                        item.image.platform = value.actions[0].metadata.symbol;
                        if (value.actions[0].metadata.bio != null)
                            item.image.description = value.actions[0].metadata.bio.length < 64 ? value.actions[0].metadata.bio : value.actions[0].metadata.bio.slice(0, 32) + '...';
                        item.image.image = 'https://ipfs.rss3.page/ipfs/' + value.actions[0].metadata.profile_uri[0].slice(7, value.actions[0].metadata.profile_uri[0].length);
                        item.image.size = '73px'
                    }

                    item.actions1 = value.actions[0].metadata.type;
                    item.actions3 = value.actions[0].metadata.platform;

                    item.actions2 = 'a ' + value.type + ' on';
                } else if (value.actions[0].type === "post") {
                    item.actions2 = 'a note on';
                    item.body = value.actions[0].metadata.body.length < 64 ? value.actions[0].metadata.body : value.actions[0].metadata.body.slice(0, 32) + '...';
                } else if (value.actions[0].type === "revise" && value.type === "revise") {
                    item.actions2 = 'a note on';
                    if (value.actions[0].metadata.body != null)
                        item.body = value.actions[0].metadata.body.length < 64 ? value.actions[0].metadata.body : value.actions[0].metadata.body.slice(0, 32) + '...';
                } else if (value.type === "transfer") {
                    item.owner = AddressToENS(value.actions[0].address_from);
                    item.actions2 = '';
                    item.actions3 = AddressToENS(value.actions[0].address_to);

                    if (value.tag === "collectible" && value.actions.length === 2) {
                        await GetProfiles(value.actions[1].address_from);
                        item.actions3 = AddressToENS(value.actions[1].address_from);


                        item.actions1 = "claimed";
                        item.actions2 = 'an NFT from';
                        item.image = {};
                        item.image.name = Helper.FixNumber(value.actions[1].metadata.value_display) + " " + value.actions[1].metadata.symbol
                        item.image.name = value.actions[1].metadata.name || value.actions[1].metadata.collection
                        item.image.description = value.actions[1].metadata.description;
                        item.image.image = value.actions[1].metadata.image;
                        item.image.size = '73px';

                        item.body = "for " + Helper.FixNumber(value.actions[0].metadata.value_display) + " " + value.actions[0].metadata.symbol
                    } else if (value.actions[0].metadata.standard === 'ERC-20' || value.actions[0].metadata.standard === 'Native') {
                        item.actions1 = 'sent to';
                        if (value.actions[0].metadata.body != null)
                            item.body = value.actions[0].metadata.body.length < 64 ? value.actions[0].metadata.body : value.actions[0].metadata.body.slice(0, 32) + '...';

                        item.image = {};
                        item.image.name = Helper.FixNumber(value.actions[0].metadata.value_display) + " " + value.actions[0].metadata.symbol
                        item.image.image = value.actions[0].metadata.image || 'https://rss3.io/images/tokens/empty-token.webp';
                        item.image.size = '24px'
                    } else {
                        item.actions1 = 'sent an NFT to';
                        item.image = {};
                        item.image.name = value.actions[0].metadata.name || value.actions[0].metadata.collection
                        item.image.description = value.actions[0].metadata.description;
                        item.image.image = value.actions[0].metadata.image;
                        item.image.size = '73px'
                    }
                } else if (value.type === "liquidity") {
                    item.actions1 = value.actions[0].metadata.action + ' ' + value.type;

                    item.image = {};
                    item.image.name = (value.actions[0].metadata.action === "add" ? '+' : '-') + ' ' + Helper.FixNumber(value.actions[0].metadata.tokens[0].value_display) + " " + value.actions[0].metadata.tokens[0].symbol
                    item.image.image = value.actions[0].metadata.tokens[0].image || 'https://rss3.io/images/tokens/empty-token.webp';
                    item.image.size = '24px'

                    item.image2 = {};
                    item.image2.name = (value.actions[0].metadata.action === "add" ? '+' : '-') + ' ' + Helper.FixNumber(value.actions[0].metadata.tokens[1].value_display) + " " + value.actions[0].metadata.tokens[1].symbol
                    item.image2.image = value.actions[0].metadata.tokens[1].image || 'https://rss3.io/images/tokens/empty-token.webp';
                    item.image2.size = '24px'
                } else if (value.type === "swap") {
                    item.actions3 = value.actions[0].metadata.protocol;
                    item.image2 = {};
                    item.image2.name = Helper.FixNumber(value.actions[0].metadata.from.value_display) + " " + value.actions[0].metadata.from.symbol
                        + ' for '
                        + Helper.FixNumber(value.actions[0].metadata.to.value_display) + " " + value.actions[0].metadata.to.symbol;
                    item.image2.imagePair = [value.actions[0].metadata.to.image || 'https://rss3.io/images/tokens/empty-token.webp', value.actions[0].metadata.from.image || 'https://rss3.io/images/tokens/empty-token.webp'];
                    item.image2.size = '24px'
                } else if (value.type === "donate") {
                    item.title = '';
                    item.actions3 = Helper.FixNumber(value.actions[0].metadata.token.value_display) + " " + value.actions[0].metadata.token.symbol;
                    item.image2 = {};
                    item.image2.name = value.actions[0].metadata.title;
                    item.image2.image = value.actions[0].metadata.logo;
                    item.image2.description = value.actions[0].metadata.description.length < 64 ? value.actions[0].metadata.description : value.actions[0].metadata.description.slice(0, 32) + '...';
                    item.image2.size = '73px'
                } else if (value.type === "follow") {
                    var addressPro = await GetProfiles(value.actions[0].metadata.address);
                    item.actions1 = value.actions[0].type + " " + (value.actions[0].metadata.name || AddressToENS(value.actions[0].metadata.address));

                    item.follow = {};
                    item.follow.image1 = 'https://cdn.stamp.fyi/avatar/' + value.owner + '?s=300';
                    item.follow.image2 = 'https://cdn.stamp.fyi/avatar/' + value.actions[0].metadata.address + '?s=300';

                    item.image = {};
                    item.image.name = AddressToENS(value.actions[0].address_from);
                    item.image.nameFollower = (value.actions[0].metadata.name || AddressToENS(value.actions[0].metadata.address));
                    item.image.imageFollow = [item.follow.image1, item.follow.image2];
                    item.image.size = '24px';

                    // item.image2 = {};
                    // item.image2.name = (value.actions[0].metadata.name || AddressToENS(value.actions[0].metadata.address));
                    // item.image2.image = item.follow.image2;
                    // item.image2.size = '24px'
                }

                item.chainLogo1 = getChainLog1(value) || "https://rss3.io/images/icons/v1/note_post.svg";
                item.chainLogo2 = getPlatformLogo(value.platform);
                item.chainLogo3 = getNetworkLogo(value.network);

                if (value.tag == 'collectible') {
                    item.contractAddress = value.actions[0].metadata.contract_address
                    item.id = value.actions[0].metadata.id
                }

                list.push(item);
            }

            return new Promise(function (resolve, reject) {
                resolve(list);
            });

        } catch (err) {
            //console.log(err)
        } finally {

        }
    }

    async function GetNoteInit(bCursor) {
        if(myFriends.length===0 && showConnect === true) {
            userCardsRemainder = false;
            setHasMore(false)
            return ;
        }
        try {
            let cursor = "";
            let result;
            const getUserSubscribeListRes = await getUserSubscribeList("0xF868531cE00BaE0dA86BCfF597C7Eb0E9f7eD104")
            if(getUserSubscribeListRes.ret!=="ok"){
                Toast.show({
                    content: 'getUserSubscribeListRes false',
                    maskClickable: false,
                })
                return ;
            }
            console.log(`getUserSubscribeListRes`,getUserSubscribeListRes)
            let addressList = getUserSubscribeListRes.result.filter((item)=> item !== 'undefined');
            if(!bCursor){
                let addressListTemp = addressList.filter((item)=>item.indexOf("0x")===0)
                let resTemp = await getUserProfileByList(addressListTemp);
                //console.log(`resTemp`,resTemp)
                if(resTemp && resTemp.total){
                    for(let item of resTemp.result){
                        AddressMap[item.address] = item.name;
                    }
                }
            }
            if (userCards.length > 0 && bCursor) {
                cursor = userCards[userCards.length - 1].hash;
                // result = await getUserMessageWithCursor(address, pageSize, requestTag, cursor)
                let requestTagTemp = requestTag?[requestTag]:false;
                //console.log(requestTagTemp)
                result = await getUserMessageWithCursorByList(addressList, pageSize, requestTagTemp, cursor)
            } else {
                // result = await getUserMessage(address, pageSize, requestTag)
                let requestTagTemp = requestTag?[requestTag]:false;
                //console.log(requestTagTemp)
                result = await getUserMessageByList(addressList,pageSize, requestTagTemp)
            }

            //console.log('result total: ', result.total);
            userCardsRemainder = result.total;

            console.log('999res',result)
            var list = [];
            for (var ii = 0; ii < result.result.length; ii++) {
                var value = result.result[ii];
                //console.log(ii)
                var item = {};
                item.hash = value.hash;
                item.address=value.owner;
                item.owner = AddressToENS(value.owner);

                item.timestamp = Helper.formatTime(value.timestamp);
                item.actions1 = value.actions[0].type;
                item.actions2 = 'on';
                item.actions3 = value.actions[0].platform;
                item.title = value.actions[0].metadata.title;
                try {
                    if (value.actions[0].type === "mint") {
                        item.image = {};
                        item.image.name = Helper.FixNumber(value.actions[0].metadata.value_display) + ' ' + value.actions[0].metadata.symbol;
                        item.image.platform = value.actions[0].metadata.symbol;
                        item.image.description = value.actions[0].metadata.description;
                        item.image.image = value.actions[0].metadata.image;
                        item.image.size = '24px'
                        item.actions2 = 'on ';
                        item.actions3 = value.actions[0].metadata.symbol;
                    }
                    else if (value.type === "profile") {
                        if (value.actions[0].metadata.name != null && value.actions[0].metadata.bio !== "") {
                            item.image = {};
                            item.image.name = value.actions[0].metadata.name;
                            item.image.platform = value.actions[0].metadata.symbol;
                            if (value.actions[0].metadata.bio != null)
                                item.image.description = value.actions[0].metadata.bio.length < 64 ? value.actions[0].metadata.bio : value.actions[0].metadata.bio.slice(0, 32) + '...';
                            item.image.image = 'https://ipfs.rss3.page/ipfs/' + value.actions[0].metadata.profile_uri[0].slice(7, value.actions[0].metadata.profile_uri[0].length);
                            item.image.size = '73px'
                        }

                        item.actions1 = value.actions[0].metadata.type;
                        item.actions3 = value.actions[0].metadata.platform;

                        item.actions2 = 'a ' + value.type + ' on';
                    }
                    else if (value.actions[0].type === "post") {
                        item.actions2 = 'a note on';
                        item.body = value.actions[0].metadata.body.length < 64 ? value.actions[0].metadata.body : value.actions[0].metadata.body.slice(0, 32) + '...';
                    }
                    else if (value.actions[0].type === "revise" && value.type === "revise") {
                        item.actions2 = 'a note on';
                        if (value.actions[0].metadata.body != null)
                            item.body = value.actions[0].metadata.body.length < 64 ? value.actions[0].metadata.body : value.actions[0].metadata.body.slice(0, 32) + '...';
                    }
                    else if (value.type === "transfer") {
                        item.owner = AddressToENS(value.actions[0].address_from);
                        item.actions2 = '';
                        item.actions3 = AddressToENS(value.actions[0].address_to);

                        if (value.tag === "collectible" && value.actions.length === 2) {
                            await GetProfiles(value.actions[1].address_from);
                            item.actions3 = AddressToENS(value.actions[1].address_from);


                            item.actions1 = "claimed";
                            item.actions2 = 'an NFT from';
                            item.image = {};
                            item.image.name = Helper.FixNumber(value.actions[1].metadata.value_display) + " " + value.actions[1].metadata.symbol
                            item.image.name = value.actions[1].metadata.name || value.actions[1].metadata.collection
                            item.image.description = value.actions[1].metadata.description;
                            item.image.image = value.actions[1].metadata.image;
                            item.image.size = '73px';

                            item.body = "for " + Helper.FixNumber(value.actions[0].metadata.value_display) + " " + value.actions[0].metadata.symbol
                        } else if (value.actions[0].metadata.standard === 'ERC-20' || value.actions[0].metadata.standard === 'Native') {
                            item.actions1 = 'sent to';
                            if (value.actions[0].metadata.body != null)
                                item.body = value.actions[0].metadata.body.length < 64 ? value.actions[0].metadata.body : value.actions[0].metadata.body.slice(0, 32) + '...';

                            item.image = {};
                            item.image.name = Helper.FixNumber(value.actions[0].metadata.value_display) + " " + value.actions[0].metadata.symbol
                            item.image.image = value.actions[0].metadata.image || 'https://rss3.io/images/tokens/empty-token.webp';
                            item.image.size = '24px'
                        } else {
                            item.actions1 = 'sent an NFT to';
                            item.image = {};
                            item.image.name = value.actions[0].metadata.name || value.actions[0].metadata.collection
                            item.image.description = value.actions[0].metadata.description;
                            item.image.image = value.actions[0].metadata.image;
                            item.image.size = '73px'
                        }
                    }
                    else if (value.type === "liquidity") {
                        item.actions1 = value.actions[0].metadata.action + ' ' + value.type;

                        item.image = {};
                        item.image.name = (value.actions[0].metadata.action === "add" ? '+' : '-') + ' ' + Helper.FixNumber(value.actions[0].metadata.tokens[0].value_display) + " " + value.actions[0].metadata.tokens[0].symbol
                        item.image.image = value.actions[0].metadata.tokens[0].image || 'https://rss3.io/images/tokens/empty-token.webp';
                        item.image.size = '24px'

                        item.image2 = {};
                        item.image2.name = (value.actions[1].metadata.action === "add" ? '+' : '-') + ' ' + Helper.FixNumber(value.actions[1].metadata.tokens[0].value_display) + " " + value.actions[1].metadata.tokens[0].symbol
                        item.image2.image = value.actions[1].metadata.tokens[0].image || 'https://rss3.io/images/tokens/empty-token.webp';
                        item.image2.size = '24px'
                    }
                    else if (value.type === "swap") {
                        item.actions3 = value.actions[0].metadata.protocol;
                        item.image2 = {};
                        item.image2.name = Helper.FixNumber(value.actions[0].metadata.from.value_display) + " " + value.actions[0].metadata.from.symbol
                            + ' for '
                            + Helper.FixNumber(value.actions[0].metadata.to.value_display) + " " + value.actions[0].metadata.to.symbol;
                        item.image2.imagePair = [value.actions[0].metadata.to.image || 'https://rss3.io/images/tokens/empty-token.webp', value.actions[0].metadata.from.image || 'https://rss3.io/images/tokens/empty-token.webp'];
                        item.image2.size = '24px'
                    }
                    else if (value.type === "donate") {
                        item.title = '';
                        item.actions3 = Helper.FixNumber(value.actions[0].metadata.token.value_display) + " " + value.actions[0].metadata.token.symbol;
                        item.image2 = {};
                        item.image2.name = value.actions[0].metadata.title;
                        item.image2.image = value.actions[0].metadata.logo;
                        item.image2.description = value.actions[0].metadata.description.length < 64 ? value.actions[0].metadata.description : value.actions[0].metadata.description.slice(0, 32) + '...';
                        item.image2.size = '73px'
                    }
                    else if (value.type === "follow") {
                        var addressPro = await GetProfiles(value.actions[0].metadata.address);
                        item.actions1 = value.actions[0].type + " " + (value.actions[0].metadata.name || AddressToENS(value.actions[0].metadata.address));

                        item.follow = {};
                        item.follow.image1 = 'https://cdn.stamp.fyi/avatar/' + value.owner + '?s=300';
                        item.follow.image2 = 'https://cdn.stamp.fyi/avatar/' + value.actions[0].metadata.address + '?s=300';

                        item.image = {};
                        item.image.name = AddressToENS(value.actions[0].address_from);
                        item.image.nameFollower = (value.actions[0].metadata.name || AddressToENS(value.actions[0].metadata.address));
                        item.image.imageFollow = [item.follow.image1, item.follow.image2];
                        item.image.size = '24px';

                        // item.image2 = {};
                        // item.image2.name = (value.actions[0].metadata.name || AddressToENS(value.actions[0].metadata.address));
                        // item.image2.image = item.follow.image2;
                        // item.image2.size = '24px'
                    }
                }catch (e){
                    //console.log(e)
                    continue;
                }

                item.chainLogo1 = getChainLog1(value) || "https://rss3.io/images/icons/v1/note_post.svg";
                item.chainLogo2 = getPlatformLogo(value.platform);
                item.chainLogo3 = getNetworkLogo(value.network);

                if (value.tag == 'collectible') {
                    item.contractAddress = value.actions[0].metadata.contract_address
                    item.id = value.actions[0].metadata.id
                }

                list.push(item);
            }

            return new Promise(function (resolve, reject) {
                resolve(list);
            });

        } catch (err) {
            //console.log(err)
        } finally {

        }
    }

    // ===============
    async function getNextData() {
        console.log(`addressCur`,addressCur)
        setLoading(true);
        if(addressCur) {
            //console.log(`getNextData`, addressCur)
            let notes = await GetNotes(addressCur, false);
            notes && setUserCards(notes)
            setHasMore(notes?.length>0)
        }else if(searchAddress) {
            let notes = await GetNotes(searchAddress, false);
            notes && setUserCards(notes)
            setHasMore(notes?.length>0)
        }
        else{
            let notes = await GetNoteInit(false);
            notes && setUserCards(notes)
            setHasMore(notes?.length>0)
        }
        setLoading(false);
    }

    const InfiniteScrollContent = ({hasMore}) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>Loading</span>
                        <DotLoading/>
                    </>
                ) : (
                    <span>--- this is bottom ---</span>
                )}
            </>
        )
    }

    async function loadMore() {
        setLoading(true);
        if(!addressCur) {
            let notes = await GetNoteInit(true);
            notes && setUserCards(userCards.concat(notes))
            setLoading(false);
            setHasMore(userCardsRemainder > 0)
            return;
        }
        let notes = await GetNotes(addressCur, true);
        notes && setUserCards(userCards.concat(notes))
        setHasMore(userCardsRemainder > 0)
        setLoading(false);
    }

    const search = async (address) => {
        if(!address){
            setSearchAddress("")
            return;
        }
        let getUserProfileRes = await getUserProfile(address);
        if (getUserProfileRes.error) {
            //console.log(`error`, getUserProfileRes.error)
            Toast.show({
                content: 'getUserProfileRes.error',
                maskClickable: false,
            })
            return;
        }
        for (let ii = 0; ii < getUserProfileRes.result.length; ii++) {
            if (getUserProfileRes.result[ii].network === "ethereum") {
                AddressMap[getUserProfileRes.result[ii].address] = getUserProfileRes.result[ii].name;
                break;
            }
        }
        // setAddressCur(address)
        setSearchAddress(address)
        const profiles = await GetProfiles(address);
        if(profiles){
            let avatarURL = 'https://cdn.stamp.fyi/avatar/' + address + '?s=300';
            let b = {
                avatar: avatarURL,
                avatarTitle: profiles['name'] ,
                avatarIntroduce: profiles['bio'] ,
                address:profiles['address'] || address
            };
            setSearchInfo(b)
        }
        else{
            let b = {
                address:addressCur
            }
            setMyInfo(b)
        }
        //console.log(`!!!` + address)
    }
    const getChainLog1 = (value) => {
        let result = false;
        if (value.tag === "social") {
            if (value.type === "revise") {
                result = note_edit;
            } else if (value.type === "post") {
                result = note_post;
            } else if (value.type === "profile") {
                if (value.actions[0].metadata.type === "update") {
                    result = note_post;
                } else if (value.actions[0].metadata.type === "create") {
                    result = profile_create;
                }
            } else if (value.type === "follow") {
                result = profile_link;
            } else {
                result = note_post;
            }
        } else if (value.tag === "collectible") {
            if (value.type === "transfer") {
                result = NFT_trade_out;
            } else if (value.type === "mint") {
                result = NFT_mint;
            } else {
                result = NFT_trade_in;
            }
        } else if (value.tag === "donation") {
            result = donation_launch;
        } else if (value.tag === "transaction") {
            if (value.type === "transfer") {
                result = Token_withdraw;
            } else if (value.type === "mint") {
                result = unknown_in;
            } else if (value.type === "burn") {
                result = unknown_burn;
            } else {
                result = Token_deposit
            }
        } else if (value.tag === "exchange") {
            if (value.type === "liquidity") {
                result = Token_liquidity;
            } else if (value.type === "swap") {
                result = Token_swap;
            } else {
                result = Token_liquidity;
            }
        } else {
            result = governance_vote;
        }
        return result;
    }
    const updateShowFilter = () => {
        //console.log(showFilter)
        let updateStatus = !showFilter;
        setShowFilter(updateStatus);
    }
    const changeRequestTag = (value) => {
        setRequestTag(value)
    }
    const updateSelectRange = (value) => {
        setSelectRange(value)
        //console.log(value)
    }
    const canUpdateFriendsList = (list) => {
        let res =  myFriendsList.length <= list.length;
        if(res){
            myFriendsList = list;
        }
        return res;
    }
    const isSubscribe = (address) => {
        let myContacts = JSON.parse(localStorage["mycontacts"] || "[]");
        let index = myContacts.findIndex(item=>item===address);
        return index >= 0;
    }
    const updateSubscribe = async (address) => {
        if(isSubscribe(address)){
            return;
            // let removeSubscribeRes = await removeSubscribe(sessionStorage["address"], address)
            // if(removeSubscribeRes.ret === "ok") {
            //     let myContacts = JSON.parse(sessionStorage["mycontacts"]);
            //     let index = myContacts.findIndex(item=>item===address);
            //     if(index !== -1) myContacts.splice(index,1);
            //     sessionStorage['mycontacts'] = JSON.stringify(myContacts);
            // }
            // else{
            //     return;
            // }
        }
        else {
            let subscribeRes = await subscribe(localStorage["address"], address);
            if(subscribeRes.ret === "ok") {
                let myContacts = JSON.parse(localStorage["mycontacts"]);
                myContacts.push(address);
                localStorage['mycontacts'] = JSON.stringify(myContacts);
            }
            else{
                return;
            }
        }
        setIsSubscribed(!isSubscribed)
    }
    return (
        <div className={"maxContainer"}>
            <PullToRefresh
                refreshingText={"loading..."}
                pullingText={"Pull down to refresh"}
                completeText={"Refresh successful"}
                canReleaseText={'Release Refresh Now'}
                onRefresh={async () => {
                    await sleep(1000)
                    await getNextData()
                }}>
                <HeaderView
                    showFilter={true}
                    showTitle={true}
                    showSearch={!addressCur || searchAddress}
                    updateSelectItem={(value)=>{
                        setShowConnect(false)
                        setAddressCur(value)
                        setSearchAddress("")

                    }}
                    showConnect={!(addressCur || myFriends.length>0 || !showConnect)}
                    connectCallBack={get_AuthCode}
                    callBackFunc={search}
                    filterCallBack={updateShowFilter}
                    showAvatar={addressCur }
                    myInfo={myInfo}
                    style={{height: "193px"}}
                />
                <div style={{padding:"0 15px"}}>
                    { searchAddress && <AvatarSubscribeView  showSubscribeBtn={true} isSubscribe={isSubscribed} subscribeClick={updateSubscribe} myInfo={searchInfo}/>}
                </div>
                <ListView dataList={userCards} loading={loading} style={{overflow: "auto"}}/>
                <InfiniteScroll className={style.main} loadMore={loadMore} hasMore={hasMore}>
                    <InfiniteScrollContent hasMore={hasMore}/>
                </InfiniteScroll>
            </PullToRefresh>
            <Popup
                visible={showFilter}
                onMaskClick={async () => {
                    setShowFilter(false)
                    await getNextData()
                }}
                position='right'
                bodyStyle={{width: '80%'}}
            >
                <SelectView
                    changeSelectTag={changeRequestTag}
                    selectTag={requestTag}
                    updateSelectRange={updateSelectRange}
                    selectRange={selectRange}
                />
            </Popup>
        </div>
    )
}
export default MyHome;
