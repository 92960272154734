import React, {useEffect, useState} from "react";
import style from './style.module.less'
import {Button, Image} from "antd-mobile";
import {AddOutline, CheckOutline} from "antd-mobile-icons";
import {useNavigate} from "react-router-dom";
import {copyStr} from "../../utils";
import failSvg from '../../asset/image/failSvg.svg'
const AvatarSubscribeView = ({showSubscribeBtn,myInfo,subscribeClick,isSubscribe}) => {
    let navigate = useNavigate();
    const toFriends = () => {
        if(!localStorage["address"]) return;
        if(localStorage["address"]!==myInfo.address) return;
        navigate("/friends",{state:{address:localStorage["address"]}})
    }
    return(
        <div className={style.Container}>
            {
                myInfo && myInfo.avatar ?
                    <Image  style={{borderRadius:"10px"}} onContainerClick={toFriends} width={58} height={58} src={(myInfo && myInfo.avatar) }/>
                        :
                    <Image  style={{borderRadius:"10px"}} onContainerClick={toFriends} width={58} height={58} src={failSvg}/>
            }
            <div className={style.InfoContainer}>
                <div className={style.Name}>{myInfo && (myInfo.avatarTitle || "")}</div>
                <div onClick={()=>copyStr(myInfo.address)} className={style.Address}>{myInfo?.roomId ? 'Room ID: ' : ''}{myInfo?.address && myInfo.address.slice(0,6)}...{myInfo?.address && myInfo?.address.slice(-6)}&ensp;
                    <svg t="1666004690847" className="icon" viewBox="0 0 1024 1024" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="2935" width="13" height="13">
                        <path
                            d="M896 810.666667l-128 0c-23.466667 0-42.666667-19.2-42.666667-42.666667 0-23.466667 19.2-42.666667 42.666667-42.666667l106.666667 0c12.8 0 21.333333-8.533333 21.333333-21.333333L896 106.666667c0-12.8-8.533333-21.333333-21.333333-21.333333L448 85.333333c-12.8 0-21.333333 8.533333-21.333333 21.333333l0 21.333333c0 23.466667-19.2 42.666667-42.666667 42.666667-23.466667 0-42.666667-19.2-42.666667-42.666667L341.333333 85.333333c0-46.933333 38.4-85.333333 85.333333-85.333333l469.333333 0c46.933333 0 85.333333 38.4 85.333333 85.333333l0 640C981.333333 772.266667 942.933333 810.666667 896 810.666667zM682.666667 298.666667l0 640c0 46.933333-38.4 85.333333-85.333333 85.333333L128 1024c-46.933333 0-85.333333-38.4-85.333333-85.333333L42.666667 298.666667c0-46.933333 38.4-85.333333 85.333333-85.333333l469.333333 0C644.266667 213.333333 682.666667 251.733333 682.666667 298.666667zM576 298.666667 149.333333 298.666667c-12.8 0-21.333333 8.533333-21.333333 21.333333l0 597.333333c0 12.8 8.533333 21.333333 21.333333 21.333333l426.666667 0c12.8 0 21.333333-8.533333 21.333333-21.333333L597.333333 320C597.333333 307.2 588.8 298.666667 576 298.666667z"
                            p-id="2936" fill="#38CA9F"></path>
                    </svg>
                </div>

            </div>
            {
                showSubscribeBtn && !myInfo.roomId &&
                <Button shape={"rounded"} className={isSubscribe?style.ReSubscribeBtn:style.SubscribeBtn} onClick={()=>subscribeClick(myInfo.address)} disabled={isSubscribe}>
                    {isSubscribe && <CheckOutline color={'#38CA9F'}/>}
                    {!isSubscribe && <AddOutline color={'#fff'}/>}&ensp;
                    {isSubscribe?"Subscribed":"Subscribe"}
                </Button>
            }
        </div>
    )
}
export default AvatarSubscribeView;
