import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useSearchParams,
  useLocation,
  redirect,
  useNavigate,
} from "react-router-dom";
import { goToAuth } from '../utils';

import Explore from "../pages/Explore";
import MyHome from "../pages/MyHome";
import Friends from "../pages/Friends";
import Friend from "../pages/Friend";
import WatchList from "../pages/WatchList";
import { getToken } from "../api/api_FeedsServer/api";
import { getUserInfo } from "../api/api_Feeds_Auth/api";
import { isMobile } from "../utils";
import UserContext from "../components/theme";

const client_id = process.env.CLIENT_ID;
const redirect_uri = process.env.FEED_URI;
const auth_uri = `${process.env.AUTH_URI}/#/login/auth?client_id=${client_id}&redirect_uri=${redirect_uri}`

export const checkAuth = async (params, setAccessToken, navigate, setAddress) => {
  if (SdmClient && SdmClient?.fetchAuthorizeCode) {
    if (localStorage.getItem("access_token")) {
      return ;
    }
    SdmClient.fetchAuthorizeCode("code", client_id, redirect_uri, async (err, value) => {
      // window.alert(value)
      const { access_token } = await getToken(value);
      if (access_token) {
        setAccessToken(access_token)
      }
    });
  } else {
    const code = params.get('authorization_code')
    if (code) {
      getToken(code).then(async ({ access_token }) => {
        if (access_token) {
          // sessionStorage.setItem('access_token', access_token);
          setAccessToken(access_token)
          let getUserInfoRes = await getUserInfo();
          setAddress(getUserInfoRes.wallet_address)
          const path = params.get('path');
          if (path) {
            navigate(path);
          } else {
            navigate('/myhome')
          }
        } else {
          if (!access_token && !isMobile()) {
            window.location.href = auth_uri + `&path=${window.location.pathname}`;
            return;
          }
        }
      });
    } else {
      if (!localStorage.getItem('access_token') && location.pathname !== '/') {
        window.location.href = auth_uri + `&path=${window.location.pathname}`;
      }
    }
  }
};

function RequireAuth({ children }) {
  const { setAccessToken, setAddress } = useContext(UserContext)
  const [params] = useSearchParams();
  const navigate = useNavigate()
  checkAuth(params, setAccessToken, navigate, setAddress);


  const path = params.get('path');
  if (path) {
    params.delete(path)
    navigate(path);
    return;
  }


  return children;
}




const AppRouter = () => {

  const auth = {
    name: '',
    isConnected: () => {
      return localStorage.getItem('access_token')
    },
    accessToken: localStorage.getItem('access_token'),
    address: localStorage.getItem('address'),
    onConnect: () => {
      goToAuth();
    },
    disConnect: () => {

    },
    setAccessToken: (accessToken) => {
      localStorage.setItem('access_token', accessToken)
    },
    setAddress: (address) => {
      localStorage.setItem('address', address)
    }
  };

  return (
    <UserContext.Provider value={auth}>
      <Router>
        <Routes>
          <Route exact path="/" element={
            <RequireAuth>
              <Explore />
            </RequireAuth>
          } />
          <Route
            exact
            path="/myhome"
            element={
              <RequireAuth>
                <MyHome />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/friends"
            element={
              <RequireAuth>
                <Friends />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/address/:id"
            element={
              <RequireAuth>
                <Friend />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/room/:id"
            element={
              <RequireAuth>
                <Friends />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/watchlist"
            element={
              <RequireAuth>
                <WatchList />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};
export default AppRouter;
