import BigNumber from "bignumber.js";

const Helper = () => {
}

Helper.FixNumber = function (aa) {
    if(aa==null||aa==="")
        aa = "0"
    if(typeof(aa)=='string')
        aa = new BigNumber(aa.replace(/,/g, ""));
    aa = new BigNumber(aa.toFormat(8).replace(/,/g, ""));
    if (aa === "NaN")
        aa = new BigNumber(0);
    if (aa === "Infinity")
        aa = new BigNumber(0);
    return aa.toFormat().replace(/,/g, "");
}

// 地址栏参数
Helper.getQueryVariable = function (variable, search) {
    if (search == null)
        search = window.location.search;
    var query = search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) { return pair[1]; }
    }
    return false;
  }

Helper.formatTime = function (timeStr){
    return timeStr.slice(0,10).replace(/-/g,"/")
}


export default Helper;
