import copy from 'copy-to-clipboard';
// import ModuleNotFoundError from 'jest-resolve/build/ModuleNotFoundError';

const client_id = process.env.CLIENT_ID;
const redirect_uri = process.env.FEED_URI;
const auth_uri = process.env.AUTH_URI;

export const copyStr = (Str) => {
    copy(Str);
};

export const getPlatformLogo = (t = '') => {
    let a = '',
        s = '';
    switch (t) {
        case 'Binance':
            s = 'https://rss3.io/images/icons/networks/bnb.png';
            break;
        case 'SushiSwap':
            s =
                'https://res.cloudinary.com/sushi-cdn/image/fetch/f_auto,c_limit,w_60,q_auto/https://app.sushi.com/images/logo.svg';
            break;
        case 'PancakeSwap':
            s = 'https://pancakeswap.finance/logo.png';
            break;
        case '1inch':
            s = 'https://app.1inch.io/assets/images/logo_small.svg';
            break;
        case 'MetaMask':
            s = 'https://metamask.io/icons/icon-72x72.png?v=48400a28770e10dd52a8c0e539aeb282';
            break;
        case 'OKEx':
            a = 'OKX';
            break;
        case 'crossbell':
        case 'crossbell.io':
            a = 'Crossbell';
            break;
        case 'xlog':
            a = 'xLog';
            break;
        case 'OpenSea':
            a = 'opensea';
            break;
        case 'Mirror':
            a = 'mirror';
            break;
        case 'Snapshot':
            a = 'snapshot';
            break;
        case 'AAVE':
            a = 'aave';
            break;
        case 'Gitcoin':
            a = 'gitcoin';
            break;
        case 'Lens':
            a = 'lens';
            break;
        case 'Farcaster':
            a = 'farcaster';
            break;
        default:
            a = t.toLowerCase();
            break;
    }
    return a !== ''
        ? `https://thumbor.rss3.dev/unsafe/60x60/https%3A%2F%2Fraw.githubusercontent.com%2FNaturalSelectionLabs%2Fweb3-logos%2Fmain%2F${a}.png`
        : s;
};
export const getNetworkLogo = (t = '') => {
    let a = '',
        s = '';
    switch (t) {
        case 'arbitrum':
            a = 'Arbitrum';
            break;
        case 'arweave':
            a = 'arweave';
            break;
        case 'avalanche':
            a = 'AVAX';
            break;
        case 'binance_smart_chain':
            a = 'binance_smart_chain';
            break;
        case 'crossbell':
            a = 'Crossbell';
            break;
        case 'ethereum':
            a = 'ethereum';
            break;
        case 'ethereum_classic':
            s =
                'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.7 220.5" data-reactroot=""><g fill="%2334d399"><path d="m2.4 98.8 65-27.4 63 28.1-63.1-99.5zm.2 30.4 64.9 37.6 66.2-37.6-65.6 91.3z"></path><path d="m67.7 84.8-67.7 28.5 67.7 37.6 65.8-36.8z"></path></g></svg>';
            break;
        case 'fantom':
            s =
                'https://fantomfoundation-prod-wp-website.s3.ap-southeast-2.amazonaws.com/wp-content/uploads/2020/04/22185032/a_rgoWCA.png';
            break;
        case 'optimism':
            a = 'Optimism';
            break;
        case 'polygon':
            a = 'Polygon';
            break;
        case 'xdai':
            a = 'xdai';
            break;
        case 'EIP-1577':
            s = 'https://rss3.io/images/icons/networks/EIP-1577.svg';
            break;
        case 'zksync':
            a = 'zksync';
            break;
        // default:
        //     a = t.toLowerCase();
        //     break
    }
    return a !== ''
        ? `https://thumbor.rss3.dev/unsafe/60x60/https%3A%2F%2Fraw.githubusercontent.com%2FNaturalSelectionLabs%2Fweb3-logos%2Fmain%2F${a}.png`
        : s;
};

export const isMobile = () => {
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !globalThis.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);
    return isIos || isAndroid;
};

export const goToAuth = () => {
    window.location.href = `${auth_uri}/#/login/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&path=${window.location.pathname}`;
};
