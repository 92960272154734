import React, {useEffect, useState} from "react";
import style from './style.module.less'
import {NavBar, Toast} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {DeleteOutline} from "antd-mobile-icons"
import {getUserSubscribeList, removeSubscribe} from "../../api/api_FeedsServer/api";
import {getUserProfileByList} from "../../api/api_Feeds/api";

const WatchList = () => {
    const navigate = useNavigate();
    const [mySubscribeList,setMySubscribeList] = useState([])
    const [refresh,setRefresh] = useState(false)
    const back = () =>{
        navigate('/myhome');
    }
    const removeAll = () => {
        console.log("removeAll")
        setRefresh(!refresh)
    }
    useEffect(()=>{
        const work = async () => {
            let getUserSubscribeListRes = await getUserSubscribeList(localStorage['address']);
            if(getUserSubscribeListRes.ret==="ok"){
                let list = [];
                let getUserProfileByListRes = await getUserProfileByList(getUserSubscribeListRes.result);
                console.log(`getUserProfileByListRes`,getUserProfileByListRes)
                list = getUserProfileByListRes.result;
                for(let i = 0 ; i < getUserSubscribeListRes.result.length;i++){
                    if(list.findIndex(item=>item.address===getUserSubscribeListRes.result[i])>=0){
                        continue;
                    }
                    list.push({name:"Not Found",address:getUserSubscribeListRes.result[i]})
                }
                console.log(`list`,list)
                setMySubscribeList(list)
            }
        }
        work()
    },[refresh])
    return (
        <div className={style.Container}>
            <NavBar onBack={back}>My Watchlist</NavBar>
            <div className={style.DetailAndRemoveAll}>
                <div className={style.Detail}>Manage your watchlist</div>
                <div className={style.RemoveAll} onClick={removeAll}>Remove all</div>
            </div>
            <div className={style.overAuto}>
                {
                    mySubscribeList.length>0 && mySubscribeList.map((item,index)=>(
                        <div className={style.ListItemContainer} key={`${index}`}>
                            <div  onClick={()=>{
                                navigate('/address/'+item.address,{state:{address:item.address}});
                            }} className={style.ItemInfo}>
                                <div className={style.ItemName}>{item.name}</div>
                                <div className={style.ItemAddress}>{item.address.slice(0,6)}...{item.address.slice(-4)}</div>
                            </div>
                            <DeleteOutline fontSize={25} onClick={()=>{
                                removeSubscribe(localStorage["address"],item.address).then(()=>{
                                    setRefresh(!refresh)
                                    let myContacts = JSON.parse(localStorage["mycontacts"]);
                                    let index = myContacts.findIndex(i=>i===item.address);
                                    if(index !== -1) myContacts.splice(index,1);
                                    localStorage['mycontacts'] = JSON.stringify(myContacts);
                                    Toast.show({
                                        content: 'remove successfully',
                                        maskClickable: false,
                                    }) 
                                })                      
                            }}/>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default WatchList;
