import React from "react";
import style from './style.module.less'
import {Image, List, SpinLoading} from "antd-mobile";
import ImageComponent from "../ImageComponent";
import {useNavigate} from "react-router-dom";

const ListView = ({dataList, loading}) => {
    console.log('999datalist',dataList)
    let navigate = useNavigate()
    const goNftProfile = (item) => {
        console.log('999item', item)
        if (!item.contractAddress || !item.id) {
            return
        }
        console.log('999item', item)
        let nft_profile = process.env.NFT_PROFILE
        console.log('click',`${nft_profile}/nft/${item.contractAddress}/${item.id}`)
        document.location = `${nft_profile}/nft/${item.contractAddress}/${item.id}`
        // const w=window.open('about:blank');
        // w.location.href="https://dapp-alpha.sending.me/nft/"+item.contractAddress+ "/"+item.id
    }
    return(
        <div className={`${style.Container} ${loading ? style.Loading : ''}`}>
            {loading ? <SpinLoading style={{ '--size': '48px' }} /> : null }
            <List style={{ overflowY:"auto",overflowX:"hidden", filter: loading ? 'blur(2px)' : '' }}>
                { dataList && dataList.map((item,index) => (
                    item && <List.Item key={index} className={style.itemContainer} style = {{lineHeight:1.2}}>
                        <div className={style.platformInfo} key={item.hash}>
                            <Image lazy className={style.platformInfo_type} src={item.chainLogo1} width={'40px'} height={'20px'}  onClick={()=>{console.log(item.hash)}} />&ensp;
                            {item.chainLogo2 ? <Image lazy className={style.platformInfo_platform} src={item.chainLogo2} width={'16px'} height={'16px'}/> : null}&ensp;
                            {item.chainLogo3 ? <Image lazy className={style.platformInfo_platform} src={item.chainLogo3} width={'16px'} height={'16px'}/> : null}&ensp;
                            <div className={style.wrapText} style={{fontSize:"13px",color:"#828792"}}>{item.timestamp}</div>
                        </div>
                        <div className={style.firstTitle}>
                            <div onClick={()=>{
                                navigate("/address/"+item.address,{state:{address:item.address}})
                            }}>{item.owner}</div>
                            <div className={style.content}> &ensp; {item.actions1} {item.actions2}&ensp;</div>
                            <div className={style.last}>{item.actions3}</div>
                        </div>
                        <h5 className={style.secondTitle}>
                            {item.title}
                        </h5>
                        <div className={style.content}>
                            {item.body}
                        </div>
                        <div onClick={() => 
                            goNftProfile(item)
                        }>
                            <ImageComponent props={{...item.image,padding:"5px 0"}}/>
                            <ImageComponent props={{...item.image2,padding:"5px 0"}}/>
                        </div>

                    </List.Item>
                ))}
            </List>
        </div>
    )
}
export default ListView;
