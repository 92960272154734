import {instance} from "./api_instance";
import axios from "axios";

// export const getToken = async (code) => {
//     return (await axios.get("http://localhost:3670/getToken",{params:{code}})).data
// }
export const getToken = async (code) => {
    return (await instance.get("/getToken",{params:{code}})).data
}
export const getUserSubscribeList = async (user) =>{
    return (await instance.get("/query",{params:{user}})).data
}
export const removeSubscribe = async (user,follow) =>{
    return (await instance.get("/unscribe",{params:{user,follow}})).data
}
export const subscribe = async (user,follow) =>{
    if(!user) return;
    return (await instance.get("/subscribe",{params:{user,follow}})).data
}




