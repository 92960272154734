import React, {useState} from "react";
import style from './style.module.less'
import {Button, Divider, Dropdown, Radio, Space} from "antd-mobile";
import Select from "react-select";
import {CloseCircleOutline} from "antd-mobile-icons";

const SelectView = ({changeSelectTag,selectTag,updateSelectRange,selectRange}) => {
    const tags= [
        {name:"All",target:""},
        {name:"Collectible",target:"collectible"},
        {name:"Social",target:"social"},
        {name:"Donation",target:"donation"},
        {name:"Transaction",target:"transaction"},
        {name:"Exchange",target:"exchange"},
        {name:"Governance",target:"governance"},
    ]
    const tagClick = (item) => {
        console.log(`tagClick`,item)
        changeSelectTag(item.target)
    }
    const checkIcon = (
        <div style={{width:"var(--icon-size)",height:"var(--icon-size)",borderRadius:"999px",border:"2px solid #38CA9F",padding:"3px"}}>
            <div style={{width:"100%",height:"100%",borderRadius:"999px",background:"#38CA9F"}}/>
        </div>
    );
    const unCheckIcon = (
        <div style={{width:"var(--icon-size)",height:"var(--icon-size)",borderRadius:"999px",border:"2px solid #666666"}} />
    );
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    const [selectedOption, setSelectedOption] = useState({ value: 'chocolate', label: 'Chocolate' },);
    const [selectedSquadList,setSelectSquadList] = useState([
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ])
    const showSelectedOption = false;
    return (
        <div className={style.Container}>
            <div className={style.Title}>
                Type
            </div>
            <div className={style.TagsContainer}>
                {
                    tags && tags.map((item,index)=>(
                        <Button
                            onClick={()=>tagClick(item)}
                            className={item.target===selectTag.toLowerCase()?style.TagsBtnSelected:style.TagsBtn}
                            key={`${item.name}`}
                        >{item.name}</Button>
                    ))
                }
            </div>
            <Divider className={style.Divider}/>
            <div className={style.RadiosContainer}>
                <Radio.Group defaultValue={selectRange} onChange={updateSelectRange}>
                    <Radio icon={checked => checked ? checkIcon: unCheckIcon} value={"All"}>All</Radio>
                    <Radio icon={checked => checked ? checkIcon: unCheckIcon} value={"Contacts"}>Only Contacts</Radio>
                </Radio.Group>
            </div>
            <Divider className={style.Divider}/>
            {showSelectedOption && <div>
                <div className={style.Title}>
                    Squad
                </div>
                <Select
                    className={style.DropDown}
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                />
                {
                    selectedSquadList && selectedSquadList.map((item,index)=>(
                        <div className={style.SelectedContainer} key={`${item.label}_${index}`}>
                            <img src={"https://uploadfile.bizhizu.cn/up/b3/f5/da/b3f5da7304bb7c1727e48de50233d902.jpg.source.jpg"}/>
                            <div className={style.Title}>Alex DAO</div>
                            <CloseCircleOutline fontSize={20} color={"#999"}/>
                        </div>
                    ))
                }
                <div className={style.Title}>
                    Room
                </div>

                <Select
                    className={style.DropDown}
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                />
            </div>}

        </div>
    )
}
export default SelectView;
