import React from 'react'

const userProps = {
    name: '',
    isConnected: ()=>{
        return localStorage.getItem('access_token')
      },
    accessToken: localStorage.getItem('access_token'),
    address: localStorage.getItem('address'),
    onConnect: () => {
        goToAuth();
    },
    disConnect: ()=>{
    },
    setAccessToken: (accessToken)=>{
        localStorage.setItem('access_token', accessToken)
    },
    setAddress: (address)=>{
        localStorage.setItem('address', address)
    }
  };
  
  const UserContext = React.createContext(userProps);
  

  export default UserContext;