import React, { useContext, useEffect, useState } from "react";
import style from "./style.module.less";
import { Image, Input, Popover } from "antd-mobile";
import {
  CloseCircleOutline,
  FilterOutline,
  LeftOutline,
  MoreOutline,
  SearchOutline,
  SetOutline,
} from "antd-mobile-icons";
import AvatarSubscribeView from "../AvatarSubscribeView";
import { useNavigate } from "react-router-dom";
import failSvg from "../../asset/image/failSvg.svg";
import UserContext from "../theme";

const HeaderView = ({
  isSubscribe,
  subscribeClick,
  showFilter,
  showSubscribeBtn,
  showBackAndMore,
  showTitle,
  updateSelectItem,
  showConnect,
  callBackFunc,
  filterCallBack,
  connectCallBack,
  showSearch,
  showAvatar,
  myInfo,
  avatar,
}) => {
  const { onConnect, isConnected } = useContext(UserContext);
  const isLogin = isConnected();
  let navigate = useNavigate();
  const debounce = (fn, ms = 500) => {
    let timeoutId;
    return function (e) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(this, arguments);
      }, ms);
    };
  };
  const changeValue = (val) => {
    callBackFunc(val);
    // setValue()
  };
  const actions = [
    {
      key: "Disconnect",
      icon: <CloseCircleOutline />,
      text: "Disconnect",
      action: () => {
        localStorage.clear();
        window.location.href = process.env.FEED_URI;
        // window.location.reload()
      },
    },
    {
      key: "MyWatchList",
      icon: <SetOutline />,
      text: "My watchlist",
      action: () => {
        console.log("myWatchList");
        navigate("/watchlist");
      },
    },
  ];
  useEffect(() => {
    if (!isSubscribe) {
      setActions2([]);
      return;
    }
    const actions2 = {
      key: "Unsubscribe",
      icon: null,
      text: "Unsubscribe",
      action: () => {
        if (subscribeClick) {
          subscribeClick(myInfo.address, true);
        }
      },
    };
    setActions2([actions2]);
  }, [isSubscribe, myInfo]);

  const [actions2, setActions2] = useState([]);

  return (
    <div className={style.Container}>
      {isLogin && showTitle && (
        <Popover.Menu
          actions={actions}
          mode="dark"
          placement="bottom-start"
          onAction={(node) => node.action()}
          trigger="click"
        >
          <Image
            className={style.RightHeader}
            src={avatar || failSvg}
            width={30}
            height={30}
            fit="cover"
            style={{ borderRadius: 15 }}
          />
        </Popover.Menu>
      )}
      {showBackAndMore && (
        <div className={style.BackAndMore}>
          <LeftOutline
            onClick={() => {
              navigate("/");
            }}
            color={"#fff"}
            fontSize={19}
          />
          <div></div>
          <Popover.Menu
            actions={actions2}
            mode="dark"
            placement="bottom-start"
            onAction={(node) => node.action()}
            trigger="click"
          >
            <MoreOutline color={"#fff"} fontSize={29} />
          </Popover.Menu>
        </div>
      )}
      {!isConnected() && (
        <div
          onClick={() => {
            onConnect();
            // connectCallBack()
          }}
          className={style.ConnectBtn}
        >
          Connect
        </div>
      )}
      {showTitle && (
        <div className={style.HeaderName}>
          <div
            onClick={() => {
              navigate("/");
              updateSelectItem();
            }}
            className={!showAvatar ? style.activeView : style.view}
          >
            Explore
          </div>
          {isLogin && (
            <div
              onClick={() => {
                navigate("/myhome");
                updateSelectItem(localStorage["address"]);
              }}
              className={showAvatar ? style.activeView : style.view}
              style={{ marginLeft: "10px" }}
            >
              Subscriptions
            </div>
          )}
        </div>
      )}
      {showFilter && (
        <div className={style.FilterBtn} onClick={filterCallBack}>
          <FilterOutline fontSize={19} />
        </div>
      )}
      {showAvatar && (
        <div style={{ marginTop: "20px", width: "100%" }}>
          <AvatarSubscribeView
            isSubscribe={isSubscribe}
            subscribeClick={subscribeClick}
            myInfo={myInfo}
            showSubscribeBtn={showSubscribeBtn}
          />
        </div>
      )}
      {showSearch && (
        <div className={style.SearchContainer}>
          <Input
            placeholder="Address or ENS"
            // value={value}
            onEnterPress={(event) => {
              changeValue(event.target.value);
            }}
            // onChange={debounce(changeValue,500)}
          />
          <div className={style.searchIcon}>
            <SearchOutline fontSize={12} />
          </div>
        </div>
      )}
    </div>
  );
};
export default HeaderView;
