import {instance, baseUrl} from "./api_instance";
// let client_id = "4665656473";
// let client_id = "466565647373";
let client_id = process.env.CLIENT_ID;
// let redirect_uri = "http://47.243.39.240";
// let redirect_uri = "http://localhost:3000";
let redirect_uri = process.env.FEED_URI;
const auth_uri = process.env.AUTH_URI
export const get_AuthCode = async () => {
    try {
        if (SdmClient && SdmClient.fetchAuthorizeCode) {
            if(localStorage.getItem("access_token")) {
                return;
            }
            SdmClient.fetchAuthorizeCode("code", client_id, redirect_uri, (err, value) => {
                // window.alert(value)
                if (!err) {
                    const newUrl = `${redirect_uri}/?client_id=${client_id}&authorization_code=${value}`
                    window.location.href = newUrl;
                } else {
                    window.alert(err)
                }
            });
            return;
        }
    } catch (e) {
        console.log(`get_AuthCode_App`,e.message)
    }

    window.location.href = `${auth_uri}/#/login/auth?client_id=${client_id}&redirect_uri=${redirect_uri}`
}
export const getUserInfo = async (wallet_address) => {
    return (await instance.get("/get_user_info",{params:{client_id,wallet_address}})).data
}
export const getUserFriends = async (wallet_address,offset,limit) => {
    return (await instance.get("/contacts_list",{params:{client_id,wallet_address,offset,limit}})).data
}
export const getAvatar = (
    base,
    mxc,
    width,
    height,
    resizeMethod,
    allowDirectLinks = false,
) => {
    if (typeof mxc !== "string" || !mxc) {
        return '';
    }
    if (mxc.indexOf("mxc://") !== 0) {
        if (allowDirectLinks) {
            return mxc;
        } else {
            return '';
        }
    }
    base = base || new URL(baseUrl).origin;
    let serverAndMediaId = mxc.slice(6); // strips mxc://
    let prefix = "/_api/media/r0/download/";
    const params = {};

    if (width) {
        params["width"] = Math.round(width);
    }
    if (height) {
        params["height"] = Math.round(height);
    }
    if (resizeMethod) {
        params["method"] = resizeMethod;
    }
    if (Object.keys(params).length > 0) {
        // these are thumbnailing params so they probably want the
        // thumbnailing API...
        prefix = "/_api/media/r0/thumbnail/";
    }

    const fragmentOffset = serverAndMediaId.indexOf("#");
    let fragment = "";
    if (fragmentOffset >= 0) {
        fragment = serverAndMediaId.substr(fragmentOffset);
        serverAndMediaId = serverAndMediaId.substr(0, fragmentOffset);
    }

    const urlParams = (Object.keys(params).length === 0 ? "" : ("?" + utils.encodeParams(params)));
    return base + prefix + serverAndMediaId + urlParams + fragment;
}

export const getRoomMembers = async (room_id) => {
    return (await instance.get("/get_room_members",{params:{client_id, room_id}})).data
}

export const getRoomInfo = async (room_id) => {
    return (await instance.get("/get_room_info",{params:{client_id, room_id}})).data
}
